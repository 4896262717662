<template>

  <div class="uk-card uk-card-default uk-card-small tm-ignore-container"
       uk-sticky="offset: 90; bottom: true; media: @m;">
    <div class="uk-card-header">
      <div class="uk-grid-small uk-child-width-1-1" uk-grid>
        <section class="uk-flex uk-flex-center">
          <div uk-form-custom style="overflow: hidden;text-align:center;width: 120px; height:120px"
               class="js-upload  uk-width-1-3 uk-width-1-4@s uk-width-1-2@m uk-margin-auto uk-visible-toggle uk-position-relative uk-border-circle uk-overflow-hidden uk-light">
            <input type="file" id="file" ref="file" @change="previewImage" accept="image/*" style="font-size: 0px"/>

            <div class="uk-height-1-1" style="overflow: hidden" v-if="imageData.length == 0">
              <img v-if="currentUser.image !==null" v-show="currentUser.image.length > 0"
                   class="uk-height-1-1" tabindex="-1" width="150"
                   :src="`/uploadingDir/${currentUser.image}`">
              <img v-else class="uk-width-medium uk-height-1-1" tabindex="-1" width="64" src="../../assets/user_pro.png">
            </div>
            <div class="uk-height-1-1" style="overflow: hidden" v-else>
              <img class=" uk-height-1-1" tabindex="-1" width="150" :src="imageData">
            </div>
            <a tabindex="-1"
               class="uk-link-reset uk-overlay-primary uk-position-cover uk-hidden-hover"
               href="#">
              <div class="uk-position-center"><span
                uk-icon="icon: camera; ratio: 1.25;"></span></div>
            </a></div>
        </section>
        <div class="uk-text-center">
          <div class="uk-h4 uk-margin-remove">{{currentUser.firstName}}</div>
          <div class="uk-text-meta">{{$t("additional.joined")}} {{currentUser.createdAt | moment}}</div>
<!--          <div v-if="$store.getters.isLoggedIn"-->
<!--               v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN' || currentUser.authorities[0].authority === 'ROLE_PROFESSOR'"-->
<!--               class="uk-text-meta">{{currentUser.point}} P-->
<!--&lt;!&ndash;            class="uk-text-meta">{{currentUser.point | currency}} P&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="uk-text-meta">{{currentUser.reviewCount}} </div>-->
        </div>
        <div>
          <div class="uk-grid-small uk-flex-center" uk-grid>
<!--            <div>-->
<!--              <router-link-->
<!--                class="uk-button uk-button-default uk-button-small"-->
<!--                title="Settings"-->
<!--                tag="a"-->
<!--                :to="{name: 'mysettings'}"-->
<!--                  <span class="uk-margin-xsmall-right"-->
<!--                        uk-icon="icon: cog; ratio: .75;"></span><span>{{$t("globViews.settings.set")}}</span>-->
<!--              </router-link>-->
<!--            </div>-->
            <div>
              <button type="button" @click.prevent="logout" class="uk-button uk-button-default uk-button-small" href="#"
                      title="Log out"><span uk-icon="icon: sign-out; ratio: .75;"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <nav>
        <ul class="uk-nav uk-nav-default tm-nav">
          <li v-bind:class="{ 'uk-active': $router.currentRoute.name === 'myinfo'}">
            <router-link
              title="Personal Info"
              tag="a"
              :to="{name: 'myinfo'}"
            >
            {{$t("navMenu.pInfo")}}
            </router-link>
          </li>
          <li v-bind:class="{ 'uk-active': $router.currentRoute.name === 'mysettings'}">
            <router-link
              title="Settings"
              tag="a"
              :to="{name: 'mysettings'}">
              {{$t("navMenu.set")}}
            </router-link>
          </li>


<!--          <li v-if="$store.getters.isLoggedIn"-->
<!--              v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN' || currentUser.authorities[0].authority === 'ROLE_PROFESSOR'"-->
<!--              :class="{ 'uk-active': $router.currentRoute.name === 'myhistory'}">-->
<!--            <router-link-->

<!--              title="Purchase history"-->
<!--              tag="a"-->
<!--              :to="{name: 'myhistory'}"-->
<!--            >{{$t("navMenu.pHistory")}}-->
<!--            </router-link>-->
<!--          </li>-->
<!--          <li v-if="$store.getters.isLoggedIn"-->
<!--              v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN' || currentUser.authorities[0].authority === 'ROLE_PROFESSOR'"-->
<!--              :class="{ 'uk-active': $router.currentRoute.name === 'myhistoryorganiz'}">-->

<!--            <router-link-->

<!--              title="Purchase ranking"-->
<!--              tag="a"-->
<!--              :to="{name: 'myhistoryorganiz'}"-->
<!--            >-->
<!--             {{$t("navMenu.pRanking")}}-->
<!--            </router-link>-->
<!--          </li>-->
<!--          <li v-if="$store.getters.isLoggedIn"-->
<!--              v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN' || currentUser.authorities[0].authority === 'ROLE_PROFESSOR'">-->
<!--            <a href="#">{{$t("navMenu.rViewing")}} (31)</a></li>-->
<!--          <li v-if="$store.getters.isLoggedIn"-->
<!--              v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN' || currentUser.authorities[0].authority === 'ROLE_PROFESSOR'">-->
<!--            <a href="#">{{$t("navMenu.pDetails")}}</a></li>-->

<!--          <li v-if="$store.getters.isLoggedIn"-->
<!--              v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN'">-->
<!--            <router-link title="Quiz list" tag="a" :to="{name: 'quizlist'}">-->
<!--              {{$t("navMenu.checkRe")}}-->
<!--            </router-link>-->
<!--          </li>-->

<!--          <li v-if="$store.getters.isLoggedIn"-->
<!--              v-show="currentUser.authorities[0].authority === 'ROLE_USER'"><a href="#modal-evalution" uk-toggle>-->
<!--            {{$t("navMenu.sAssessment")}}-->
<!--          </a></li>-->

          <li><a @click.prevent="logout" href="#">{{$t("navMenu.logout")}}</a></li>
        </ul>
      </nav>
    </div>


<!--    <div id="modal-evalution" uk-modal>-->
<!--      <div class="uk-modal-dialog uk-modal-body">-->
<!--        <h2 class="uk-modal-title">{{$t("navMenu.sAssessment")}}</h2>-->
<!--        <div class="uk-margin">-->
<!--          <label class="uk-form-label">{{$t("navMenu.grade")}}</label>-->
<!--          <div class="uk-form-controls">-->
<!--            <select class="uk-select" id="form-kurs">-->
<!--              <option>{{$t("navMenu.grade")}} 1</option>-->
<!--              <option>{{$t("navMenu.grade")}} 2</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->


<!--        <div class="uk-margin">-->
<!--          <label class="uk-form-label">{{$t("navMenu.sub")}}</label>-->
<!--          <div class="uk-form-controls">-->
<!--            <select class="uk-select" id="form-category">-->
<!--              <option>{{$t("navMenu.sub")}} 1</option>-->
<!--              <option>{{$t("navMenu.sub")}} 2</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->


<!--        <p class="uk-text-right">-->
<!--          <button type="button" class="uk-button uk-button-default uk-modal-close" >{{$t("navMenu.cancel")}}</button>-->
<!--          <button type="button" @click.prevent="evalutionstart()" class="uk-button uk-button-primary uk-modal-close" >-->
<!--            {{$t("navMenu.start")}}-->
<!--          </button>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->


    <!--//password change modal-->
    <div id="modal-passwordchange" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">
          {{$t("navMenu.pChange")}}</h2>

        <div v-if="error!==null" class="uk-alert-warning" uk-alert>
          <p v-html="error" class="uk-text-center"></p>
        </div>

        <div v-if="message!==null"
             :class="{ 'uk-alert-success': success, 'uk-alert-danger':success===false }" uk-alert>

          <p v-html="message"></p>
        </div>

        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: lock"></span>
            <input v-model="newpassword" placeholder="New password" class="uk-input" type="password"/>
          </div>
        </div>
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: lock"></span>
            <input v-model="confirmpassword" placeholder="Confirm password"
                   :class="{ 'uk-form-danger': validconfirmpass }" class="uk-input" type="password"/>
          </div>
        </div>

        <p class="uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-modal-close" >{{$t("navMenu.cancel")}}</button>
          <button type="button" @click="changepass" class="uk-button  uk-button-primary" >{{$t("navMenu.save")}}</button>
        </p>
      </div>
    </div>

  </div>
</template>


<script>
  import UserService from '@/services/UserService'
  import {mapGetters} from 'vuex'
  import moment from 'moment'
  import UIkit from 'uikit'
  import _ from 'lodash'
  export default {
    name: 'Home',
    components: {
    },
    data() {
      return {
        newpassword: null,
        confirmpassword: null,
        error: null,
        validconfirmpass: false,
        message: null,
        success: false,
        imageData: "",
        file: null
      }
    },
    mounted() {
      // console.info('App this router:', this.$router)
      // console.info('App currentRoute:', this.$router.currentRoute)
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser'])
    },
    filters: {
      moment: function (date) {
        return moment(date).format('YYYY.MM.DD')
        // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
      }
    },
    watch: {
      // $route(to, from) {
      //   if (from !== to) {
      //   }
      // },
      confirmpassword: _.debounce(async function () {
        if (this.confirmpassword === this.newpassword) {
          this.validconfirmpass = false
        } else {
          this.validconfirmpass = true
        }
      }, 50)
    },
    methods: {
      logout() {
        this.$store.dispatch('setToken', null)
        this.$store.dispatch('setUser', null)
        if (window.examMode == 'undefined' || window.examMode == undefined)
        {
          this.$router.push({
            name: 'login'
          })
        }
        else{
          window.examMode.exitExamMode();
          this.$router.push({
            name: 'login'
          })
        }
      },
      evalutionstart() {
        this.$router.push({
          name: 'myevalution'
        })
      },
      previewImage: function (event) {
        this.file = this.$refs.file.files[0];
        var input = event.target;
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.imageData = e.target.result;
            console.log("image data is here")
            console.log(this.imageData)
          }
          reader.readAsDataURL(input.files[0]);
        }
        if (this.file)
          this.modifyProfile()
      },
      async modifyProfile() {
        try {
          let formData = new FormData();
          formData.append('image', this.file);
          const response = await UserService.modifyUser2(formData)
          console.log(response.data)
          if (response.data.status === 200) {
            this.message = response.data.message
            this.me()
          }
        } catch (error) {
          console.log(error)
          this.error = error.response.data.message
        }
      },
      async me() {
        try {
          const response = await UserService.me();
          console.log("current user me")
          console.log(response)
          this.$store.dispatch("setUser", response.data);
          UIkit.modal.alert(this.message).then(function () {
            console.log('Alert closed.')
          });
        } catch (error) {
          console.log(error)
          this.error = error.response.data.message;
        }
      },
      async changepass() {
        this.message = null
        this.error = null
        if (this.isLoggedIn) {
          let valid = this.newpassword == this.confirmpassword;
          if (!valid) {
            this.error = 'new password not valid'
            this.scrollToTop()
            return;
          }
          try {
            const response = await UserService.passwordChange({
              oldpass: '',
              newpass: this.newpassword,
              changeType: 'defaultpassupdate'
            });
            this.success = response.data.success
            this.message = response.data.message
            this.currentpassword = null
            this.newpassword = null
            this.confirmpassword = null
            if (response.data.success) {
              //default password soligdson bol passType 1 bolgoj password shinechleh msgiig hide hiine
              this.currentUser.passType = 1
              this.$store.dispatch("setUser", this.currentUser);
            }
          } catch (error) {
            // console.log(error.response)
            this.error = error.response.data.message;
            this.currentpassword = null
            this.newpassword = null
            this.confirmpassword = null
          }
        }
      }
    },
  }
</script>

<style scoped>
</style>
